:export {
  menu-color: #000;
  menu-color-active: #c41230;
  menu-background: #fff;
}

.video-bar {
  margin-top: 15px;
}

.video-box {
  width: 100%;
  height: 100%;
}
.video-box .vjs-custom-skin > .video-js .vjs-big-play-button {
  background-color: rgba(0, 0, 0, 0.45);
  font-size: 3.5em;
  border-radius: 50%;
  height: 2em !important;
  line-height: 2em !important;
  margin-top: -1em !important;
  margin-left: -1em !important;
  width: 2em !important;
  outline: none;
  border: none;
}
.video-box .vjs-custom-skin > .video-js .vjs-big-play-button .vjs-icon-placeholder::before {
  font-size: 3rem;
}