:export {
  menu-color: #000;
  menu-color-active: #c41230;
  menu-background: #fff;
}

.tdleft tr td {
  text-align: left;
}

.show-service .show-item {
  overflow-x: auto;
}
.show-service .show-item .show-scroll {
  min-width: 768px;
}
.show-service .show-item .table-item {
  margin-top: 1.42rem;
}
.show-service .show-item .table-item table {
  width: 100%;
  border-collapse: collapse;
}
.show-service .show-item .table-item table tr th {
  background: #CA3935;
  color: #fff;
  font-size: 1.07rem;
  padding: 15px;
}
.show-service .show-item .table-item table tr td {
  background: #ffffff;
  color: #666666;
  font-size: 1.07rem;
  border-right: 10px solid #F4F5F4;
  border-top: 10px solid #F4F5F4;
  padding: 15px;
}
.show-service .show-item .table-item table tr td:last-child {
  border-right: 0px;
}
.show-service .show-item .table-item .table-hotel tr td {
  border-right: 0px solid #F4F5F4;
}
.show-service .show-item .title {
  margin-top: 1.42rem;
  padding: 15px;
  background-color: #CA3935;
  border-radius: 10px 10px 0px 0px;
  color: #ffffff;
}
.show-service .show-item .title .txt {
  font-size: 2.14rem;
  font-weight: 600;
}
.show-service .show-item .title .subtxt {
  font-size: 1.42rem;
  font-weight: 400;
  color: #ffffff;
  margin-left: 1.42rem;
}
.show-service .show-item .warn {
  color: #CA3935;
  font-size: 1.42rem;
  margin-top: 10px;
  text-align: left;
}