:export {
  menu-color: #000;
  menu-color-active: #c41230;
  menu-background: #fff;
}

.detail-wrapper .image-list-wrapper {
  margin-top: 20px;
  border-radius: 8px;
  padding: 0;
}
.detail-wrapper .image-list-wrapper .image-list-item {
  border: 1px solid #c78a29;
  padding: 10px;
  border-radius: 5px;
  background: #fff;
  height: 40rem;
  overflow: hidden;
}
.detail-wrapper .image-list-wrapper .image-list-item > img {
  object-fit: contain;
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.org-list .org-list-tip {
  color: #c78a29;
  font-size: 2.14rem;
  font-weight: 600;
  font-family: PingFangSC-Semibold, PingFang SC;
}
.org-list .org-list-item-container {
  border-radius: 8px;
  padding: 10px;
}
.org-list .org-list-item-container.org-one {
  max-width: 400px;
}
.org-list .org-list-item-container .org-list-item {
  background: #fff;
  border: 1px solid #c78a29;
  border-radius: 5px;
}
.org-list .org-list-item-container .org-list-item .org-list-item-img img {
  width: 100%;
  border-radius: 5px 5px 0px 0px;
}
.org-list .org-list-item-container .org-list-item .org-list-item-title {
  text-align: center;
  font-size: 1.07rem;
  padding: 12px 0px;
}
.org-list .org-list-item-container .org-list-item .org-list-item-title.ch {
  font-size: 1.07rem;
}