$base-color-blue-light-9: mix($base-color-white, $base-color-blue, 90%);
$base-color-black-light-1: mix($base-color-white, $base-color-black, 10%);
.el-menu {
  background-color: $base-color-white;
  // border-right: solid 1px #e6e6e6;
  border-right: none !important;
}

.el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
.el-menu--horizontal > .el-submenu .el-submenu__title:hover {
  background-color: $base-color-white;
}

.el-menu.el-menu--horizontal {
  // border-bottom: solid 1px #e6e6e6 ;
  border-bottom: none !important;
  background: none !important;
}
.el-menu--horizontal>.el-menu-item:not(.is-disabled):focus, .el-menu--horizontal>.el-menu-item:not(.is-disabled):hover, .el-menu--horizontal>.el-submenu .el-submenu__title:hover{
  background: none !important;
}
.el-menu.el-menu--popup.el-menu--popup-bottom-start{
// background: none !important;
}
.el-menu--horizontal .el-menu .el-menu-item, .el-menu--horizontal .el-menu .el-submenu__title{
  // background-image: url(https://iscfiles.oss-accelerate.aliyuncs.com/tmp/WCEC/2023/web/mainbg.png) !important;
}
.el-menu--horizontal {
  border-right: none;
}

.el-menu--horizontal > .el-menu-item {
  color: $base-color-grey;
  // border-bottom: 2px solid transparent !important;
  border-bottom: none !important;
}

.el-menu--horizontal > .el-submenu:focus .el-submenu__title,
.el-menu--horizontal > .el-submenu:hover .el-submenu__title {
  color: $base-color-black-light-1;
}

.el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  color: $base-color-black-light-1;
  // border-bottom: 2px solid $base-color-blue;
  border-bottom: none !important;
}

.el-menu--horizontal > .el-submenu .el-submenu__title {
  color: $base-color-grey;
  // border-bottom: 2px solid transparent;
  border-bottom: none !important;
}

.el-menu--horizontal .el-menu .el-menu-item,
.el-menu--horizontal .el-menu .el-submenu__title {
  color: $base-color-grey;
  background-color: $base-color-white;
}

.el-menu--horizontal .el-menu .el-menu-item.is-active,
.el-menu--horizontal .el-menu .el-submenu.is-active > .el-submenu__title {
  color: $base-color-black-light-1;
}

.el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  color: $base-color-black-light-1;
}

.el-menu--horizontal > .el-menu-item.is-active {
  color: $base-color-black-light-1;
  // border-bottom: 2px solid $base-color-blue;
  border-bottom: none !important;
}

.el-menu--collapse .el-submenu .el-menu {
  border: 0;
}

.el-menu--popup {
  border: none;
}

.el-menu-item {
  color: $base-color-black-light-1;
}

.el-menu-item i {
  color: $base-color-grey;
}

.el-menu-item:focus,
.el-menu-item:hover {
  background-color: $base-color-blue-light-9;
}

.el-menu-item.is-disabled {
  background: 0 0 !important;
}

.el-menu-item.is-active {
  color: $base-color-blue;
}

.el-submenu__title {
  color: $base-color-black-light-1;
}

.el-submenu__title i {
  color: $base-color-grey;
}

.el-submenu__title:focus,
.el-submenu__title:hover {
  background-color: $base-color-blue-light-9;
}

.el-submenu__title.is-disabled {
  background: 0 0 !important;
}

.el-submenu__title:hover {
  background-color: $base-color-blue-light-9;
}

.el-submenu .el-menu {
  border: none;
}

.el-submenu.is-active .el-submenu__title {
  border-bottom-color: $base-color-blue;
}

.el-submenu.is-disabled .el-menu-item,
.el-submenu.is-disabled .el-submenu__title {
  background: 0 0 !important;
}

.el-menu-item-group__title {
  color: $base-color-grey;
}
