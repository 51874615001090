:export {
  menu-color: #000;
  menu-color-active: #c41230;
  menu-background: #fff;
}

.list-item-org {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0px 1px 17px 0px rgba(0, 0, 0, 0.12);
  padding: 5px 20px;
}
.list-item-org .list-content-org {
  border-top: 2px solid #c78a29;
  margin: 20px;
}
.list-item-org .list-content-org .list-title-org {
  padding: 20px 0px;
  font-weight: bold;
  font-size: 16px;
}
.list-item-org .list-content-org .list-text-org {
  text-align: left;
  line-height: 22px;
  text-indent: 2em;
}
.list-item-org .list-media-org {
  width: 100%;
  padding: 20px;
  height: 5rem;
}
.list-item-org .list-media-org img {
  object-fit: contain;
  margin: 0 auto;
  width: 100%;
  max-height: 5rem;
  display: block;
}