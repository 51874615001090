@use "sass:math";@import "~@/common/styles/variables.scss";
@mixin base-scrollbar {
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(#000000, 0.1);
    border: 3px solid transparent;
    border-radius: 7px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: rgba(#000000, 0.2);
  }
}

html{
  body{
    // filter:grayscale(100%);
    position: relative;
    height: 100vh;
    padding: 0;
    margin: 0;
    overflow: hidden;
    font-size: 14px;
    color: #515a6e;
    font-family: "PingFang SC", Arial, "Microsoft YaHei", sans-serif;
    box-sizing: border-box;

    background: $base-color-bg;
    // background: $base-color-background;
    background-image: url('https://iscfiles.oss-accelerate.aliyuncs.com/tmp/WCEC/2023/web/mainbg.png') !important;
    background-repeat: repeat !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    * {
      box-sizing: border-box;
      outline: none !important;
      @include base-scrollbar;
    }
    #app {
      height: 100vh;
      overflow: auto;
      @include base-scrollbar;
      .site-main {
        transition: $base-transition;

        .site-app-main {
          width: 100%;
          // padding: $base-padding;
          overflow: hidden;
          transition: $base-transition;

          > section {
            // background: $base-color-white;
            transition: $base-transition;

            > [class*='-container'] {
              min-height: $base-keep-alive-height;
              // padding: $base-padding;
              // background: $base-color-white;
              transition: $base-transition;
            }
          }
        }
      }
    }
  }
}
//明细组件
.detail-container {
  // background: $base-color-background !important;
  // padding: 20px;
  .detail-wrapper {
    max-width: 1200px;
    // background: white;
    margin: 10px auto;
    margin-bottom: $px50;
    text-align: left;
    padding: 15px;
    .detail-tip {
      margin-bottom: 20px ;
      text-align: left;
    }
    img.detail-img {
      width: 100%;
      max-width: 400px;
      // margin: 0 auto;
      display: block;
    }
    .detail-item {
      // text-align: center;
      .detail-title {
        font-size: 20px;
        margin-top: 20px;
      }
      .detail-abs {
        margin-top: 10px;
        font-size: 14px;
      }
    }
  }
}
//议程
.agenda-nodata {
  font-size: $font20;
  color: #000;
  margin-top:20px
}
.agenda-section {
  // background: linear-gradient(90deg, #ca3935 0%, #bb08ab 100%);
  background-image: url('https://iscfiles.oss-accelerate.aliyuncs.com/tmp/WCEC/2023/web/homebgagn.jpg') !important;
  min-height: 300px;
  padding-top: 30px;
  padding-bottom: 30px;
  border-top: 6px solid #C78A29;
  border-bottom: 6px solid #C78A29;
  .center{
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.content-container {
  // background: $base-color-background !important;
  .content-wrapper {
    max-width: 1200px;
    margin: 10px auto;
    padding: 15px;
    margin-bottom: $px50;
  }
}
.swiper-out-wrapper {
  overflow: hidden;
}
.nomore{
 padding: 20px;
}
.more {
	text-align: right;
	// color:#CA3935;
  color: #999999;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	.tool{
		display: flex;
		align-items: center;
		justify-content: flex-end;
    padding:5px 10px;
    border: 1px solid #999999;
    border-radius: 6px;
    cursor: pointer;
		.txt {
		}
		img {
			margin-left: 4px;
			width: 10px;
			height: 14px;
		}
	}
}
.pdt20{
 padding-top: 20px;
}
.pdr20{
  padding-right: 20px;
}
/* 文字显示 */
.text-line{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.txt-one-line,
.txt-two-line,
.txt-three-line,
.txt-four-line {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-box-orient: vertical;
}

.txt-one-line {
	-webkit-line-clamp: 1;
}
.txt-two-line {
	-webkit-line-clamp: 2;
}
.txt-three-line {
	-webkit-line-clamp: 3;
}
.txt-four-line {
	-webkit-line-clamp: 4;
}

/**图片特效**/
.img-hover{
  position: relative;
  &:hover{
    &::after{
      opacity: 1;
    }
  }
  &::after{
    content: '';
    opacity: 0;
    transition: all 800ms ease;
    position: absolute;
    top: 0px;
    left:0px;
    bottom: 0px;
    right: 0px;
    background-color: rgba(0,0,0,0.3);
    background-image: url("https://iscfiles.oss-accelerate.aliyuncs.com/tmp/ETE/web/hover.png") ;
    background-repeat: no-repeat;
    background-size: 50px 50px;
    background-position:50% 50%;
    border-radius: 8px 8px 0 0;
    color: #CA3935;
  }
}
.pos-hover{
  position: relative;
  &:hover,&:focus{
    &::after{
      opacity: 1;
    }
  }
  &::after{
    content: '';
    opacity: 0;
    transition: all 800ms ease;
    position: absolute;
    top: 0px;
    left:0px;
    bottom: 0px;
    right: 0px;

    background-repeat: no-repeat;
    background-size: 100% 100%;
    border-radius: 8px 8px 0 0;
  }
}
.pos-zh .pos-hover{
  &.pos2::after{
    background-image: url("https://iscfiles.oss-accelerate.aliyuncs.com/tmp/WCEC/2023/web/menu/reghl.png") ;
  }
  &.pos4::after{
    background-image: url("https://iscfiles.oss-accelerate.aliyuncs.com/tmp/WCEC/2023/web/menu/congIcon1.png") ;
  }
  &.pos6::after{
    background-image: url("https://iscfiles.oss-accelerate.aliyuncs.com/tmp/WCEC/2023/web/menu/agendahl.png") ;
  }
  &.pos8::after{
    background-image: url("https://iscfiles.oss-accelerate.aliyuncs.com/tmp/WCEC/2023/web/menu/filehl.png") ;
  }
}
.pos-en .pos-hover{
  &.pos2::after{
    background-image: url("https://iscfiles.oss-accelerate.aliyuncs.com/tmp/WCEC/2023/web/menu/reghlen.png") ;
  }
  &.pos4::after{
    background-image: url("https://iscfiles.oss-accelerate.aliyuncs.com/tmp/WCEC/2023/web/menu/exhhlen.png") ;
  }
  &.pos6::after{
    background-image: url("https://iscfiles.oss-accelerate.aliyuncs.com/tmp/WCEC/2023/web/menu/agendahlen.png") ;
  }
  &.pos8::after{
    background-image: url("https://iscfiles.oss-accelerate.aliyuncs.com/tmp/WCEC/2023/web/menu/filehlen.png") ;
  }
}
.pos-th .pos-hover{
  &.pos2::after{
    background-image: url("https://iscfiles.oss-accelerate.aliyuncs.com/tmp/WCEC/2023/web/menu/reghlth.png") ;
  }
  &.pos4::after{
    background-image: url("https://iscfiles.oss-accelerate.aliyuncs.com/tmp/WCEC/2023/web/menu/exhhlth1.png") ;
  }
  &.pos6::after{
    background-image: url("https://iscfiles.oss-accelerate.aliyuncs.com/tmp/WCEC/2023/web/menu/agendahlth.png") ;
  }
  &.pos8::after{
    background-image: url("https://iscfiles.oss-accelerate.aliyuncs.com/tmp/WCEC/2023/web/menu/filehlth.png") ;
  }
}
