@charset "UTF-8";
:export {
  menu-color: #000;
  menu-color-active: #c41230;
  menu-background: #fff;
}

html body {
  position: relative;
  height: 100vh;
  padding: 0;
  margin: 0;
  overflow: hidden;
  font-size: 14px;
  color: #515a6e;
  font-family: "PingFang SC", Arial, "Microsoft YaHei", sans-serif;
  box-sizing: border-box;
  background: #efeade;
  background-image: url("https://iscfiles.oss-accelerate.aliyuncs.com/tmp/WCEC/2023/web/mainbg.png") !important;
  background-repeat: repeat !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html body * {
  box-sizing: border-box;
  outline: none !important;
}
html body *::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
html body *::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
  border: 3px solid transparent;
  border-radius: 7px;
}
html body *::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.2);
}
html body #app {
  height: 100vh;
  overflow: auto;
}
html body #app::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
html body #app::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
  border: 3px solid transparent;
  border-radius: 7px;
}
html body #app::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.2);
}
html body #app .site-main {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border 0s, color 0.1s, font-size 0s;
}
html body #app .site-main .site-app-main {
  width: 100%;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border 0s, color 0.1s, font-size 0s;
}
html body #app .site-main .site-app-main > section {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border 0s, color 0.1s, font-size 0s;
}
html body #app .site-main .site-app-main > section > [class*=-container] {
  min-height: calc(
  100vh - 70px - 236px
);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border 0s, color 0.1s, font-size 0s;
}

.detail-container .detail-wrapper {
  max-width: 1200px;
  margin: 10px auto;
  margin-bottom: 3.57rem;
  text-align: left;
  padding: 15px;
}
.detail-container .detail-wrapper .detail-tip {
  margin-bottom: 20px;
  text-align: left;
}
.detail-container .detail-wrapper img.detail-img {
  width: 100%;
  max-width: 400px;
  display: block;
}
.detail-container .detail-wrapper .detail-item .detail-title {
  font-size: 20px;
  margin-top: 20px;
}
.detail-container .detail-wrapper .detail-item .detail-abs {
  margin-top: 10px;
  font-size: 14px;
}

.agenda-nodata {
  font-size: 1.42rem;
  color: #000;
  margin-top: 20px;
}

.agenda-section {
  background-image: url("https://iscfiles.oss-accelerate.aliyuncs.com/tmp/WCEC/2023/web/homebgagn.jpg") !important;
  min-height: 300px;
  padding-top: 30px;
  padding-bottom: 30px;
  border-top: 6px solid #C78A29;
  border-bottom: 6px solid #C78A29;
}
.agenda-section .center {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-container .content-wrapper {
  max-width: 1200px;
  margin: 10px auto;
  padding: 15px;
  margin-bottom: 3.57rem;
}

.swiper-out-wrapper {
  overflow: hidden;
}

.nomore {
  padding: 20px;
}

.more {
  text-align: right;
  color: #999999;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.more .tool {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 5px 10px;
  border: 1px solid #999999;
  border-radius: 6px;
  cursor: pointer;
}
.more .tool img {
  margin-left: 4px;
  width: 10px;
  height: 14px;
}

.pdt20 {
  padding-top: 20px;
}

.pdr20 {
  padding-right: 20px;
}

/* 文字显示 */
.text-line {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.txt-one-line,
.txt-two-line,
.txt-three-line,
.txt-four-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.txt-one-line {
  -webkit-line-clamp: 1;
}

.txt-two-line {
  -webkit-line-clamp: 2;
}

.txt-three-line {
  -webkit-line-clamp: 3;
}

.txt-four-line {
  -webkit-line-clamp: 4;
}

/**图片特效**/
.img-hover {
  position: relative;
}
.img-hover:hover::after {
  opacity: 1;
}
.img-hover::after {
  content: "";
  opacity: 0;
  transition: all 800ms ease;
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.3);
  background-image: url("https://iscfiles.oss-accelerate.aliyuncs.com/tmp/ETE/web/hover.png");
  background-repeat: no-repeat;
  background-size: 50px 50px;
  background-position: 50% 50%;
  border-radius: 8px 8px 0 0;
  color: #CA3935;
}

.pos-hover {
  position: relative;
}
.pos-hover:hover::after, .pos-hover:focus::after {
  opacity: 1;
}
.pos-hover::after {
  content: "";
  opacity: 0;
  transition: all 800ms ease;
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-radius: 8px 8px 0 0;
}

.pos-zh .pos-hover.pos2::after {
  background-image: url("https://iscfiles.oss-accelerate.aliyuncs.com/tmp/WCEC/2023/web/menu/reghl.png");
}
.pos-zh .pos-hover.pos4::after {
  background-image: url("https://iscfiles.oss-accelerate.aliyuncs.com/tmp/WCEC/2023/web/menu/congIcon1.png");
}
.pos-zh .pos-hover.pos6::after {
  background-image: url("https://iscfiles.oss-accelerate.aliyuncs.com/tmp/WCEC/2023/web/menu/agendahl.png");
}
.pos-zh .pos-hover.pos8::after {
  background-image: url("https://iscfiles.oss-accelerate.aliyuncs.com/tmp/WCEC/2023/web/menu/filehl.png");
}

.pos-en .pos-hover.pos2::after {
  background-image: url("https://iscfiles.oss-accelerate.aliyuncs.com/tmp/WCEC/2023/web/menu/reghlen.png");
}
.pos-en .pos-hover.pos4::after {
  background-image: url("https://iscfiles.oss-accelerate.aliyuncs.com/tmp/WCEC/2023/web/menu/exhhlen.png");
}
.pos-en .pos-hover.pos6::after {
  background-image: url("https://iscfiles.oss-accelerate.aliyuncs.com/tmp/WCEC/2023/web/menu/agendahlen.png");
}
.pos-en .pos-hover.pos8::after {
  background-image: url("https://iscfiles.oss-accelerate.aliyuncs.com/tmp/WCEC/2023/web/menu/filehlen.png");
}

.pos-th .pos-hover.pos2::after {
  background-image: url("https://iscfiles.oss-accelerate.aliyuncs.com/tmp/WCEC/2023/web/menu/reghlth.png");
}
.pos-th .pos-hover.pos4::after {
  background-image: url("https://iscfiles.oss-accelerate.aliyuncs.com/tmp/WCEC/2023/web/menu/exhhlth1.png");
}
.pos-th .pos-hover.pos6::after {
  background-image: url("https://iscfiles.oss-accelerate.aliyuncs.com/tmp/WCEC/2023/web/menu/agendahlth.png");
}
.pos-th .pos-hover.pos8::after {
  background-image: url("https://iscfiles.oss-accelerate.aliyuncs.com/tmp/WCEC/2023/web/menu/filehlth.png");
}