:export {
  menu-color: #000;
  menu-color-active: #c41230;
  menu-background: #fff;
}

.el-menu {
  background-color: #fff;
  border-right: none !important;
}

.el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
.el-menu--horizontal > .el-submenu .el-submenu__title:hover {
  background-color: #fff;
}

.el-menu.el-menu--horizontal {
  border-bottom: none !important;
  background: none !important;
}

.el-menu--horizontal > .el-menu-item:not(.is-disabled):focus, .el-menu--horizontal > .el-menu-item:not(.is-disabled):hover, .el-menu--horizontal > .el-submenu .el-submenu__title:hover {
  background: none !important;
}

.el-menu--horizontal {
  border-right: none;
}

.el-menu--horizontal > .el-menu-item {
  color: rgba(0, 0, 0, 0.65);
  border-bottom: none !important;
}

.el-menu--horizontal > .el-submenu:focus .el-submenu__title,
.el-menu--horizontal > .el-submenu:hover .el-submenu__title {
  color: #626b7d;
}

.el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  color: #626b7d;
  border-bottom: none !important;
}

.el-menu--horizontal > .el-submenu .el-submenu__title {
  color: rgba(0, 0, 0, 0.65);
  border-bottom: none !important;
}

.el-menu--horizontal .el-menu .el-menu-item,
.el-menu--horizontal .el-menu .el-submenu__title {
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
}

.el-menu--horizontal .el-menu .el-menu-item.is-active,
.el-menu--horizontal .el-menu .el-submenu.is-active > .el-submenu__title {
  color: #626b7d;
}

.el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  color: #626b7d;
}

.el-menu--horizontal > .el-menu-item.is-active {
  color: #626b7d;
  border-bottom: none !important;
}

.el-menu--collapse .el-submenu .el-menu {
  border: 0;
}

.el-menu--popup {
  border: none;
}

.el-menu-item {
  color: #626b7d;
}

.el-menu-item i {
  color: rgba(0, 0, 0, 0.65);
}

.el-menu-item:focus,
.el-menu-item:hover {
  background-color: #faebeb;
}

.el-menu-item.is-disabled {
  background: 0 0 !important;
}

.el-menu-item.is-active {
  color: #CA3935;
}

.el-submenu__title {
  color: #626b7d;
}

.el-submenu__title i {
  color: rgba(0, 0, 0, 0.65);
}

.el-submenu__title:focus,
.el-submenu__title:hover {
  background-color: #faebeb;
}

.el-submenu__title.is-disabled {
  background: 0 0 !important;
}

.el-submenu__title:hover {
  background-color: #faebeb;
}

.el-submenu .el-menu {
  border: none;
}

.el-submenu.is-active .el-submenu__title {
  border-bottom-color: #CA3935;
}

.el-submenu.is-disabled .el-menu-item,
.el-submenu.is-disabled .el-submenu__title {
  background: 0 0 !important;
}

.el-menu-item-group__title {
  color: rgba(0, 0, 0, 0.65);
}

.vab-layout .el-menu {
  color: #515a6e !important;
  background: #fff !important;
}
.vab-layout .el-menu .el-submenu__title {
  color: #515a6e !important;
  background: #fff !important;
}
.vab-layout .el-menu .el-menu-item {
  color: #515a6e !important;
  background: #fff !important;
}
.vab-layout .vab-side-bar,
.vab-layout .comprehensive-bar-container {
  color: #515a6e !important;
  background: #fff !important;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08) !important;
}
.vab-layout .vab-side-bar .el-menu-item:hover,
.vab-layout .comprehensive-bar-container .el-menu-item:hover {
  color: #CA3935 !important;
}
.vab-layout .vab-side-bar .el-menu-item.is-active,
.vab-layout .comprehensive-bar-container .el-menu-item.is-active {
  color: #CA3935 !important;
}

.site-header .el-menu--horizontal .el-menu-item.is-active:hover {
  color: #CA3935 !important;
}
.site-header .el-menu--horizontal .el-menu-item.is-active.is-active {
  color: #CA3935 !important;
}
.site-header .el-menu--horizontal .el-submenu:hover,
.site-header .el-menu--horizontal .el-menu-item:hover {
  color: #CA3935 !important;
}
.site-header .el-menu--horizontal .el-submenu.is-active,
.site-header .el-menu--horizontal .el-menu-item.is-active {
  color: #CA3935 !important;
}