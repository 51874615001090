@use "sass:math";@import "~@/common/styles/variables.scss";$base-color-blue:#CA3935;
$base-color-blue-light: mix($base-color-white, $base-color-blue, 90%);
@import './menu.scss';
@mixin container {
  color: #515a6e !important;
  background: $base-menu-background !important;
}

@mixin container-column {
  color: #515a6e !important;
  background: #f7f9ff !important;
}

@mixin active {
  &:hover {
    color: $base-color-blue !important;
    // background-color: $base-color-blue-light !important;
  }

  &.is-active {
    color: $base-color-blue !important;
    // background-color: $base-color-blue-light !important;
  }
}
//皮肤
.vab-layout {
    .el-menu {
      @include container;

      .el-submenu__title {
        @include container;
      }

      .el-menu-item {
        @include container;
      }
    }

    .vab-side-bar,
    .comprehensive-bar-container {
      @include container;
      box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08) !important;

      .el-menu-item {
        @include active;
      }
    }
  }
.site-header{
  .el-menu {
    &--horizontal {
      .el-menu-item {
        &.is-active {
          @include active;
        }
      }

      .el-submenu,
      .el-menu-item {
        @include active;
      }
    }
  }
}