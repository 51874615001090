:export {
  menu-color: #000;
  menu-color-active: #c41230;
  menu-background: #fff;
}

.file-list {
  margin: 20px auto;
}
.file-list .file-item-wrapper {
  padding: 10px;
}
.file-list .file-item-wrapper .file-item {
  background: #fff;
  padding: 15px 20px;
  text-align: left;
}
.file-list .file-item-wrapper .file-item > img {
  width: 2.64rem;
  vertical-align: bottom;
}
.file-list .file-item-wrapper .file-item .txt {
  margin-left: 1.42rem;
}
.file-list .file-item-wrapper .file-item .txt a {
  font-size: 1.42rem;
  color: #666666;
}